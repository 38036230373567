<template>
    <div>
		<v-card>
			<v-divider class="mt-4"></v-divider>

			<!-- actions -->
			<v-card-text class="d-flex align-center flex-wrap pb-0">
				<!-- search -->
				<v-text-field
					:aria-label="t('Buscar por nombre de usuario')"
					:placeholder="t('Buscar por nombre de usuario')"
					outlined
					hide-details
					dense
					class="user-search me-3 mb-4"
					v-model="searchQuery"
					@keyup="handleSearch"
				>
				<!-- v-model="searchQuery" -->
					<!-- @keyup="handleSearch" -->
				</v-text-field>

				<v-spacer></v-spacer>
				<v-select
					style="width: 0px"
					class="mb-4 mr-1"
					:items="[{name: '-', id: -1},...countryList]"
					hide-details="true"
					:label="t('País')"
					item-text="name"
					item-value="name"
					outlined
					dense
					v-model="countryFilter"
					@change="setFilterCountry"
					>
          <template v-slot:selection="data">
            {{ countryParser[data.item.name] ? countryParser[data.item.name] : '-' }}
          </template>

          <template v-slot:item="data">
            {{ countryParser[data.item.name] ? countryParser[data.item.name] : '-' }}
          </template>
        </v-select>

				<v-select
					style="width: 0px"
					class="mb-4"
					:items="[{name: '-', value: -1}, {name: $t('Administrador'), value: 0}, {name: $t('Representante de país'), value: 1}, {name: $t('Coordinador regional'), value: 2},{name: $t('Representante escolar'), value: 3}]"
					hide-details="true"
					:label="t('Rol')"
					item-text="name"
					item-value="value"
					outlined
					dense
					v-model="stateFilterRole"
					@change="setFilterRole"
					></v-select>
					<!-- v-model="stateFilterRole" -->
				<v-spacer></v-spacer>

				<div class="d-flex align-center flex-wrap" v-if="getUserRole === 0">
				<v-btn
					color="primary"
					class="mb-4 me-3"
					@click="$refs.user_form_modal.openModal()"
					>
				<v-icon>{{ icons.mdiPlus }}</v-icon>
				<span>{{t('Crear Usuario')}}</span>
				</v-btn>

			</div>
			</v-card-text>
				<!-- table -->
				<v-data-table
				:no-data-text="getError ? getError : $t('No hay datos disponibles.')"
				:headers="tableColumns"
				:items="userListTable"
				:page="current_page"
				:server-items-length="totalUserListTable"
				:loading="loading"
        @update:page="changingUsersPage($event)"
        @update:items-per-page="changingUsersPerPage($event)"
        @update:sort-desc="sortingPage($event)"
				:footer-props="{
          'items-per-page-options': [
            10,
            25,
            50,
            100
          ],
          'items-per-page-text': `${$t('Items por página')}`,
          'page-text': `{0}-{1} ${$t('de')} {2}`,
        }"
				>

				<!-- name -->
				<template #[`item.name`]="{item}">

					<div class="d-flex flex-column ms-3">
            <v-btn text class="no-uppercase" @click="$refs.user_detail.openDetailModal(item.id)">{{ item.name }}</v-btn>
						<!-- <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name}}</span> -->
					</div>

				</template>

				<!-- role -->
				<template #[`item.role`]="{item}">
					{{ `${roleParser[item.role]}` | translate }}
				</template>

				<!-- phone -->
				<template #[`item.phone`]="{item}">
					{{ item.phone ? item.phone : '-'}}
				</template>

				<!-- email -->
				<template #[`item.email`]="{item}">
					{{ item.email ? item.email : '-' }}
				</template>

				<!-- action -->
				<template #[`item.actions`]="{ item }" v-if="getUserRole === 0">

					<v-btn
						class="me-2 icon-with-label"
						color="alt-primary"
						text
						@click="$refs.user_form_modal.openModal(item.id)"
					>
						<div class="d-flex align-center">
							<span class="label">
								{{$t('Editar')}}
							</span>
							<v-icon size="1.5rem">
								{{ icons.mdiPencilOutline }}
							</v-icon>
						</div>
					</v-btn>

					<v-btn
						class="me-2 icon-with-label"
						color="error"
						text
						@click="$refs.delete_user_modal.openModal(item)"
					>
						<div class="d-flex align-center">
							<span class="label">
								{{$t('Eliminar')}}
							</span>
							<v-icon size="1.5rem">
								{{ icons.mdiDeleteOutline }}
							</v-icon>
						</div>
					</v-btn>
				</template>

				</v-data-table>

			<user-form-modal
				ref="user_form_modal"
				@reload-user-list="getUsersList()"
			/>

			<delete-user-modal
				ref="delete_user_modal"
				@reload-user-list="getUsersList()"
			/>

      <UserDetailModal
        ref="user_detail"
        :roleParser="roleParser"
      />

		</v-card>
    </div>
</template>

<script>
// const debounce = require('debounce');

// import data from './datatable'
import { ref } from '@vue/composition-api';
import { useUtils } from '@core/libs/i18n'
import UserFormModal from '@/components/Users/UserFormModal';
import DeleteUserModal from '@/components/Users/DeleteUserModal';
import countriesParserMixin from '@/utils/countriesParserMixin';
import debounce from '../../plugins/debounce.js'
import UserDetailModal from './UserDetailModal.vue';

import {
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPencilOutline,
} from '@mdi/js'


export default {
  components: {
    UserFormModal,
    DeleteUserModal,
    UserDetailModal
},
  data: ()  => {
		return {
			userList: [],
			countryList: [],
			countryFilter: -1,
			current_page: 1,
			page_size: 10,
      sortDesc: false,
			getError:null,
			loading: false,
			stateFilterRole: -1,
			roleParser: {
				0: "Administrador",
				1: "Representante de país",
				2: "Coordinador regional",
				3: "Representante escolar",
			},
		}
  },
	mixins: [countriesParserMixin],
  methods: {
		async getUsersList() {
			this.loading = true
			try {
				const query = {
					page_size: this.page_size,
					page: this.current_page,
					search: this.searchQuery,
					role: this.stateFilterRole,
					country: this.countryFilter,
          order: this.sortDesc
				}
				const response = await this.$api.getUsersList(query)
				this.userListTable = response.results
				this.totalUserListTable = response.count
			} catch (error) {
				if(error?.response?.status === 403) {
					this.getError = error?.response?.data?.detail
				}
			} finally {
				this.loading = false
			}
		},
		async getCountryList() {
			try {
				const response = await this.$api.getCountryList({ paginated: false })
				this.countryList = response
			} catch(e) {
				console.log(e)
			}
		},
		setFilterCountry() {
			this.current_page = 1
			this.getUsersList()
		},
		changingUsersPage(e) {
      this.current_page = e
      this.getUsersList()
		},
    changingUsersPerPage(e){
      this.page_size = e
      this.getUsersList()
    },
    sortingPage(e) {
      this.sortDesc = e
      this.getUsersList()
    },
		handleSearch: debounce(function() {
      this.current_page = 1;
      this.getUsersList();
    }, 400),
		setFilterRole() {
			this.current_page = 1
				this.getUsersList()
		},
  },
  mounted() {
	this.getUsersList()
	this.getCountryList()
  },
  computed: {
    getUserRole() { return this.$store.getters["session/getUserRole"](); },
    tableColumns() {
      let tableColumns = [
			{ text: this.$t('Nombre'), value: 'name', align: 'center'},
			{ text: this.$t('Rol'), value: 'role', align: 'center', sortable: false },
			{ text: this.$t('Teléfono'), value: 'phone', align: 'center', sortable: false },
			{ text: 'Email', value: 'email', align: 'center', sortable: false },
      ];
      if (this.getUserRole !== 0) {
        return tableColumns;
      } else {
        tableColumns.push({ text: this.$t('Acciones'), value: 'actions', align: 'center', sortable: false })
        return tableColumns
      }
		}
  },
  setup() {
		const totalUserListTable = ref(0);
		const userListTable = ref([]);
		const searchQuery = ref('');
		const { t } = useUtils()

		return {
			totalUserListTable,
			userListTable,
			searchQuery,
			t,
			icons: {
				mdiSquareEditOutline,
				mdiDotsVertical,
				mdiPencilOutline,
				mdiDeleteOutline
			},
		}
  },
}
</script>

<style scoped>
.no-uppercase {
  text-transform: unset !important;
}
</style>
